import React from 'react';
import badge from './eulonfc_badge.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={badge} className="App-logo" alt="logo" />
        <p>
          We are back...
        </p>
      </header>
    </div>
  );
}

export default App;
